import React from 'react'
import Page from 'src/components/layout/Page'
import Meta from 'src/components/layout/Meta'
import Nav from 'src/components/common/Nav'
import Fullpage from 'src/components/common/Fullpage'

export default function Home({ pageContext, data }) {
  return (
    <Page>
      <Meta pageContext={pageContext} />
      <Nav />
      <Fullpage logos={[]} subtitle={'404'} images={[]} />
    </Page>
  )
}
